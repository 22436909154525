import React from "react";
import "./App.css";
import ScrollHelpers from "./scrollHelper";
import emailjs from "@emailjs/browser";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menu_offset: [], con_text: "" };
    this.menuMove = this.menuMove.bind(this);
    this.backToTopClicked = this.backToTopClicked.bind(this);
    this.detectCurrent = this.detectCurrent.bind(this);
    this.inputContactText = this.inputContactText.bind(this);
    this.initialize = this.initialize.bind(this);
    this.getOffset = this.getOffset.bind(this);
    this.tabFunction = this.tabFunction.bind(this);
    this.toggle_on = this.toggle_on.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.refresh = this.refresh.bind(this);
    this.form = React.createRef();
  }
  async componentDidMount() {
    await this.getOffset();
    await this.detectCurrent();
    await window.addEventListener("scroll", (e) => this.detectCurrent(e));
    await window.addEventListener("resize", (e) => this.detectCurrent(e));

    await window.addEventListener("resize", (e) => this.getOffset(e));
  }
  refresh() {
    window.location.reload();
    window.scroll(0, 0);
  }
  menuMove(id) {
    ScrollHelpers.scrollTo(id);
  }
  sendEmail = (e) => {
    if (document.querySelector("#agree").checked === false) {
      e.preventDefault();
      alert("개인정보 수집 및 이용에 동의해주세요.");
    } else {
      e.preventDefault();
      emailjs
        .sendForm(
          // "service_fu4x8mq",
          // "template_v8gipqm",
          // this.form.current,
          // "qIZYrZA5o2Unh-6MA"
          "service_aw37t4d",
          "template_hly2d66",
          this.form.current,
          "q-o1WnQB6dOaQAOlQ"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("메일이 발송되었습니다.", this.refresh());
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  initialize() {
    document.querySelector("#start_menu").classList.remove("active");
    document.querySelector("#vision_menu").classList.remove("active");
    document.querySelector("#history_menu").classList.remove("active");
    document.querySelector("#intro_menu").classList.remove("active");
    document.querySelector("#functions_menu").classList.remove("active");
    document.querySelector("#contact_menu").classList.remove("active");
  }
  toggle_on() {
    if (
      document.querySelector(".toggle").classList.contains("toggle_on") ===
      false
    ) {
      document.querySelector(".toggle").classList.add("toggle_on");
      document.querySelector("#toggle").style.display = "block";
      return false;
    } else {
      document.querySelector(".toggle").classList.remove("toggle_on");
      document.querySelector("#toggle").style.display = "none";
      return false;
    }
  }
  tabFunction(e, tabe) {
    if (e.target.classList.contains("ac_tab") === false) {
      let tab = document.querySelector(".tab").children;
      for (let a = 0; a < tab.length; a++) {
        tab[a].classList.remove("ac_tab");
      }
      e.target.classList.add("ac_tab");
      document.querySelector("#tab01").style.display = "none";
      document.querySelector("#TBM").style.display = "none";
      document.querySelector("#tab02").style.display = "none";
      document.querySelector("#tab03").style.display = "none";
      document.querySelector("#tab04").style.display = "none";
      document.querySelector("#tab05").style.display = "none";
      document.querySelector("#tab06").style.display = "none";
      document.querySelector(tabe).style.display = "block";
      return false;
    } else {
      return false;
    }
  }

  getOffset() {
    let wrap = document.querySelector(".wrap").children;
    for (let i = 0; i < wrap.length; i++) {
      this.state.menu_offset.push(wrap[i].offsetTop);
    }
    console.log(this.state.menu_offset);
  }
  async backToTopClicked() {
    ScrollHelpers.scrollTop(250);
    await this.detectCurrent();
  }
  async inputContactText(e) {
    await this.setState({ con_text: e.target.value });
  }
  detectCurrent() {
    if (window.scrollY <= this.state.menu_offset[3] && window.scrollY >= 0) {
      this.initialize();
      document.querySelector("#start_menu").classList.add("active");
      document.querySelector("header").classList.remove("scrolled");
    }
    if (
      window.scrollY <= this.state.menu_offset[4] + 10 &&
      window.scrollY >= this.state.menu_offset[3] - 10
    ) {
      this.initialize();
      document.querySelector("#vision_menu").classList.add("active");
      document.querySelector("header").classList.add("scrolled");
    }
    if (
      window.scrollY <= this.state.menu_offset[5] + 10 &&
      window.scrollY >= this.state.menu_offset[4] - 10
    ) {
      this.initialize();
      document.querySelector("#history_menu").classList.add("active");
      document.querySelector("header").classList.add("scrolled");
    }
    if (
      window.scrollY <= this.state.menu_offset[6] + 10 &&
      window.scrollY >= this.state.menu_offset[5] - 10
    ) {
      this.initialize();
      document.querySelector("#intro_menu").classList.add("active");
      document.querySelector("header").classList.add("scrolled");
    }

    if (
      window.scrollY <= this.state.menu_offset[7] + 10 &&
      window.scrollY >= this.state.menu_offset[6] - 10
    ) {
      this.initialize();
      document.querySelector("#functions_menu").classList.add("active");
      document.querySelector("header").classList.add("scrolled");
    }

    if (
      window.scrollY <= this.state.menu_offset[8] + 10 &&
      window.scrollY >= this.state.menu_offset[7] - 10
    ) {
      this.initialize();
      document.querySelector("#contact_menu").classList.add("active");
      document.querySelector("header").classList.add("scrolled");
    }
  }

  render() {
    return (
      <>
        <div className="wrap">
          <header>
            <a href="#" className="logo"></a>
            <ul id="menu_wrap">
              <li onClick={this.backToTopClicked}>
                <a id="start_menu">창업동기</a>
              </li>
              <li
                onClick={() => {
                  this.menuMove(document.querySelector("#vision"));
                }}>
                <a id="vision_menu">비전</a>
              </li>
              <li
                onClick={() => {
                  this.menuMove(document.querySelector("#history"));
                }}>
                <a id="history_menu">연혁</a>
              </li>
              <li
                onClick={() => {
                  this.menuMove(document.querySelector("#intro"));
                }}>
                <a id="intro_menu">현장통소개</a>
              </li>
              <li
                onClick={() => {
                  this.menuMove(document.querySelector("#functions"));
                }}>
                <a id="functions_menu">현장통주요기능</a>
              </li>

              <li
                onClick={() => {
                  this.menuMove(document.querySelector("#contact"));
                }}>
                <a id="contact_menu">이용문의</a>
              </li>
            </ul>
            <a
              href="https://hongtong.kr/adc/login.php"
              className="pc_move not_mobile"
              target="_blank">
              <div
                style={{
                  display: "inline",
                  padding: "10px",
                  borderRadius: "12px",
                  border: "1px solid red",
                }}>
                <i></i>현장통 PC 버전
              </div>
            </a>
            <a
              href="https://hongtong.kr/tong.apk"
              className="download not_mobile">
              <div
                style={{
                  display: "inline",
                  padding: "10px",
                  borderRadius: "12px",
                  border: "1px solid red",
                }}>
                <i></i>APK 다운로드
              </div>
            </a>
            <div className="toggle" onClick={this.toggle_on}>
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </header>
          <div className="on_mobile">
            <div id="toggle" style={{ display: "none" }}>
              <div className="dark_wall"></div>
              <div className="mobile_menu">
                <a href="https://hongtong.kr/adc/login.php">
                  <i></i>현장통 PC 버전
                </a>

                <a href="https://hongtong.kr/tong.apk">
                  <i></i>APK 다운로드
                </a>
                <div style={{ clear: "both" }}></div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.backToTopClicked();
                    this.toggle_on();
                  }}>
                  창업동기
                </div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.menuMove(document.querySelector("#vision"));
                    this.toggle_on();
                  }}>
                  비전
                </div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.menuMove(document.querySelector("#history"));
                    this.toggle_on();
                  }}>
                  연혁
                </div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.menuMove(document.querySelector("#intro"));
                    this.toggle_on();
                  }}>
                  현장통 소개
                </div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.menuMove(document.querySelector("#functions"));
                    this.toggle_on();
                  }}>
                  현장통 주요기능
                </div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.menuMove(document.querySelector("#downloads"));
                    this.toggle_on();
                  }}>
                  사용설명서
                </div>
                <div
                  className="menu_move"
                  onClick={() => {
                    this.menuMove(document.querySelector("#contact"));
                    this.toggle_on();
                  }}>
                  이용문의
                </div>
              </div>
            </div>
          </div>
          <section id="start">
            <div className="section_inner" style={{ paddingTop: "100px" }}>
              <div className="pc_big"></div>
              <div className="pc_big_rest">
                <h1>
                  <b>현장통 앱</b> 하나로
                  <br />
                  건설현장 안전보건
                  <br />
                  관리체계 구축 가능
                </h1>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <a href="#">
                          <div></div>구글 플레이스토어
                        </a>
                      </td>
                      <td>
                        <a href="#">
                          <div></div>애플 앱스토어
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                '(주)통하는사람들' 은<br />
                ICT 기술을 기반으로 '사람'과 '소통'에 집중한
                <br />
                현장관리 플랫폼을 만들었습니다.
              </div>
            </div>
          </section>
          <div style={{width: '100%'}}>
            <div>
              <iframe style={{width:"100%", height:"57vw"}} src="https://www.youtube.com/embed/OfD3zoHt3Ow?si=Heyoo1c9MQoU8yIg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <section className="bg_light_gray" id="vision">
            <div className="section_inner">
              <h2>- VISION -</h2>
              <div className="dopyo"></div>
              <span className="text_align_center">
                건설인들이 안전하고 합당한 보상을 받을 수 있는
                <br />
                건설 시장을 만들고자 설립하였습니다.
              </span>
            </div>
          </section>
          <section id="history">
            <div className="section_inner">
              <h2>- HISTORY -</h2>
              <div className="history">
                <div className="hostory_cell">
                  <b>2023</b>
                  <span>12월</span> 디캠프 프론트원 입주기업 선정
                  <br /><br />
                  <span>11월</span> 벤처기업 등록
                  <br /><br />
                  <span>09월</span> 연구전담부서 설립
                  <br /><br />
                  <span>08월</span> wateRound 2기 플랫폼 협약파트너사 선정
                  <br /><br />
                  <span>08월</span> 신용보증기금 NEST 14기 선정
                  <br /><br />
                  <span>06월</span> 스마트건설 얼라이언스 협회 가입
                  <br /><br />
                  <span>05월</span> DB손해보험 스타트업 오픈스테이지 밋업 선정
                  <br /><br />
                  <span>05월</span> 한국스마트건설안전협회 가입
                  <br /><br />
                  <span>04월</span> 울산경제혁신센터 예비창업패키지 선정
                  <br /><br />
                  <span>03월</span> 통하는사람들 신규 합작법인 설립
                  {/* <br />
                  <br />
                  <b>2022</b>
                  <span>01월</span> 현장통 유료 서비스 시작
                  <br />
                  <br />
                  <b>2021</b>
                  <span>12월</span> 본사시스템 서비스 시작
                  <br />
                  <span>06월</span> 현장통 PC버전 서비스
                  <br />
                  <br />
                  <b>2020</b>
                  <span>11월</span> 현장통 IOS 테스트 버전 출시
                  <br />
                  <span>08월</span> 현장통 안드로이드 테스트 버전 출시 */}
                  {/* <br />
                  <span>04월</span> 벤쳐기업 인증(기술평가 유형)
                  <br />
                  <span>03월</span> 기업부설연구소 인증
                  <br />
                  <span>02월</span> 기술보증기금에서 소셜벤쳐 인증 */}
                  {/* <br />
                  <br />
                  <b>2019</b>
                  <span>11월</span> 비즈니스 모델 특허 등록
                  <br />
                  <br />
                  <b>2018</b>
                  <span>09월</span> (주)건설이인정받는사회 설립 */}
                  <br />
                  <br />
                </div>
              </div>
              <div className="history_rest">
                <b>현장통과 함께하는 건설사들</b>
                <br />
                <div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                  <div className="branch"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg_dark_gray" id="intro">
            <div className="section_inner">
              <h2>- 현장통앱 소개 -</h2>
              <div className="app_intro_left">
                <h3>
                  <b>현장통 앱</b> 하나로
                  <br />
                  현장안전관리 <b>가능</b>
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <a href="#">
                          <div></div>구글 플레이스토어
                        </a>
                      </td>
                      <td>
                        <a href="#">
                          <div></div>애플 앱스토어
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="clear"></div>
                오프라인 안전관리를
                <br />
                모바일 / PC에서 쉽고 간편하게!
              </div>
              <div className="app_intro_right"></div>
              <div className="clear"></div>
              <br />
              <hr></hr>
              <b className="big">현장통을 통한 안전보건관리체계 구축</b>
              <div className="ovf_after on_mobile"></div>
              <div className="ovf">
                <div className="process"></div>
              </div>
              <br />
              <hr></hr>
              <b className="big">최적의 중대재해처벌법 대응 시스템</b>
              <div className="warn"></div>
              <div className="warn_rest">
                <b>중대재해처벌법의 안전의무 규정이 명확하지 않음</b>
                <br />
                <br />
                <ul>
                  <li>안전조치에 최선을 다 했다는 것을 입증해야 함</li>
                  <li>안전관리 시스템 이용 건설사 증가</li>
                </ul>
                <br />
                사업장 내 철저한 리스크 관리로 중대재해를 사전에 방지하는 것도
                <br />
                중요하지만 그럼에도 불구하고 재해가 발생 했을 때<br />
                기업이 안전보건 조치 의무를 성실히 해왔다는 걸 ‘입증’ 하는 게
                핵심
                <br />
                <br />
                <br />
                <br />
                <b>
                  사고예방은 '<span style={{ color: "red" }}>소통</span>' 과 '
                  <span style={{ color: "red" }}>교육</span>' 에서 시작합니다!
                </b>
              </div>
            </div>
          </section>
          <section id="functions">
            <div className="section_inner">
              <h2>- 현장통 주요기능 -</h2>
              <div className="tab">
                <div
                  className="tab_cell ac_tab"
                  onClick={(e) => {
                    this.tabFunction(e, "#tab01");
                  }}>
                  위험성 평가
                </div>
                <div
                  className="tab_cell"
                  onClick={(e) => {
                    this.tabFunction(e, "#TBM");
                  }}>
                  TBM
                </div>

                <div
                  className="tab_cell"
                  onClick={(e) => {
                    this.tabFunction(e, "#tab03");
                  }}>
                  안전교육
                </div>
                <div
                  className="tab_cell"
                  onClick={(e) => {
                    this.tabFunction(e, "#tab02");
                  }}>
                  위험치워줘
                </div>
                <div
                  className="tab_cell"
                  onClick={(e) => {
                    this.tabFunction(e, "#tab04");
                  }}>
                  모바일 비상 사이렌
                </div>
                <div
                  className="tab_cell"
                  onClick={(e) => {
                    this.tabFunction(e, "#tab05");
                  }}>
                  다국어 지원
                </div>
                <div
                  className="tab_cell"
                  onClick={(e) => {
                    this.tabFunction(e, "#tab06");
                  }}>
                  모니터링
                </div>
              </div>
              <div className="tab_content" id="tab01">
                <div className="phone_three_rest">
                  <b>
                    <span>쉽고 편한</span> 위험성 평가
                  </b>
                  <br />
                  <b className="smaller">kosha 18001 인증에 최적화</b>
                  <br />
                  -2023년부터 중대재해 발생시 위험성평가 실시, 근로자 확인
                  중간점검
                  <br />
                  -현장통은 근로자가 매일 작업전 모바일로 위험성평가 확인 유도
                  <br />
                  -미확인근로자는 출근 후 1시간마다 확인요청 알람 발송
                  <br />
                  -작업중 모바일로 조치결과 쉽게 등록
                  <br />
                  -위험성평가 작성 시 공종별 위험요인, 개선대책 자동생성
                  <br />
                  &nbsp;&nbsp;(23,000여개 데이터 적용)
                </div>
                <div className="phone_three"></div>
              </div>
              <div className="tab_content" id="TBM" style={{ display: "none" }}>
                <div
                  className="phone_three_rest"
                  style={{ textAlign: "right" }}>
                  <br />
                  <b style={{ lineHeight: "70px" }}>
                    바쁜 아침 빠르고 완벽한
                    <br />
                    <span>TBM</span> 시스템
                  </b>
                  <br /> <br />
                  -협력사 관리자도 쉽게 사용
                  <br />
                  -모바일로 30초만에 TBM 작성
                  <br />
                  -QR로 근로자 전자서명
                  <br />
                  -자동 TBM일지 생성ᆞ출력
                </div>
                <div className="phone_three7"></div>
              </div>
              <div
                className="tab_content"
                id="tab02"
                style={{ display: "none" }}>
                <div
                  className="phone_three_rest"
                  style={{ textAlign: "right", paddingRigh: "70px" }}>
                  <b
                    className="smaller"
                    style={{ lineHeight: "70px", marginTop: "0px" }}>
                    <span>유해 위험요인</span>의 신고/처리
                    <br />
                    시스템
                  </b>
                  <br />
                  -모바일로 쉽게 신고, 근로자와 관리자 모두 참여
                  <br />
                  -신고자에 처리결과 피드백
                  <br />
                  -모든 과정 저장 및 출력 가능
                  <br />
                  -'작업중지권'으로 대체 가능
                  <br />
                  -근로자 '의견청취' 시스템
                </div>
                <div className="phone_three2"></div>
              </div>
              <div
                className="tab_content"
                id="tab03"
                style={{ display: "none" }}>
                <div
                  className="phone_three_rest"
                  style={{ textAlign: "right" }}>
                  <b
                    style={{
                      lineHeight: "100px",
                      textAlign: "right",
                      width: "100%",
                      display: "block",
                      paddingRight: "0px",
                    }}>
                    일반 · 정기 · 관리자
                    <br />
                    <span>특별교육</span>
                  </b>
                  <br /> <br />
                  -안전관리자 엄부시간 절약
                  <br />
                  -QR로 빠른 근로자 전자서명
                  <br />
                  -자동 교육일지 생성ᆞ출력
                </div>
                <div className="phone_three3"></div>
              </div>
              <div
                className="tab_content"
                id="tab04"
                style={{ display: "none" }}>
                <div
                  className="phone_three_rest"
                  style={{ textAlign: "right", paddingRight: "40px" }}>
                  <b>
                    모바일 <span>비상</span>사이렌
                  </b>
                  <b
                    className="smaller"
                    style={{ lineHeight: "70px", marginTop: "24px" }}>
                    현장내 작업자 모두에게
                    <br />
                    강제 발동
                  </b>
                  <br />
                  -근로자 개인별 대피상황 확인
                  <br />
                  -미대피자 위치 확인
                  <br />
                  -사이렌 발동이력 확인
                  <br />
                  -소방 대피훈련 가능
                </div>
                <div className="phone_three4"></div>
              </div>
              <div
                className="tab_content"
                id="tab05"
                style={{ display: "none" }}>
                <div
                  className="phone_three_rest"
                  style={{ textAlign: "right", paddingRight: "70px" }}>
                  <b
                    className="smaller"
                    style={{ lineHeight: "70px", marginTop: "54px" }}>
                    중국어, 베트남어,
                    <br />
                    영어, 다국어 서비스
                  </b>
                  <br /> 국내외 정세에 맞는 다국어 서비스를 오픈하여
                  <br />
                  글로벌 기업으로 발돋움하기 위해 더욱 노력 중.
                </div>
                <div className="phone_three5"></div>
              </div>
              <div
                className="tab_content"
                id="tab06"
                style={{ display: "none" }}>
                <div
                  className="phone_three_rest"
                  style={{ textAlign: "left", paddingRight: "0px" }}>
                  <br /> <br />
                  <br />
                  <b>
                    본사 <span>모니터링</span>시스템
                  </b>
                  <br /> <br />
                  실시간 본사 모니터링 시스템을 활용하여 <br />
                  건설 근로자의 안전 확보 및 건설사의 이익 증대, 시스템 개편에
                  대한 정보를 수집합니다.
                </div>
                <div className="phone_three6"></div>
              </div>
            </div>
          </section>
          <section className="bg_light_gray" id="contact">
            <div className="section_inner">
              <h2>- 이용문의 -</h2>
              <div className="form">
                <form ref={this.form} onSubmit={this.sendEmail}>
                  &nbsp;&nbsp;&nbsp;&nbsp;신청자 이름
                  <br />
                  <input
                    type="text"
                    placeholder="신청자 이름을 입력해주세요."
                    name="user_name"
                  />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;이메일
                  <br />
                  <input
                    type="text"
                    placeholder="이메일주소를 입력해주세요."
                    name="user_email"
                  />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;전화번호
                  <br />
                  <input
                    type="text"
                    placeholder="전화번호를 입력해주세요."
                    name="user_tel"
                  />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;소속/회사
                  <br />
                  <input
                    type="text"
                    placeholder="소속/회사를 입력해주세요."
                    name="org"
                  />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;메모
                  <br />
                  <textarea
                    placeholder="문의사항을 적어주세요. "
                    value={this.state.con_text}
                    onChange={this.inputContactText}
                    name="message"
                  />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;개인정보 수집 및 이용 동의
                  <pre readOnly>
                    당사는 고객님의 개인정보를 보호하고 , 적절한 서비스를
                    제공하기 위하여 기본방침을 가지고 정보를 수집하고 있습니다 .
                    문의하신 사항에 대한 답변을 받고자 하시는 경우 ,
                    개인정보취급방침에 동의하셔야 합니다 .<br />
                    <br />
                    1. 개인정보의 수집 및 이용목적
                    <br />
                    고객 문의에 대한 답변 제공 및 현황 관리
                    <br />
                    <br />
                    2. 수집하는 개인정보의 항목
                    <br />
                    소속/회사 , 이름 , 이메일 , 전화번호
                    <br />
                    <br />
                    3. 수집한 개인정보의 보유 .이용기간
                    <br />
                    개인정보 수집 및 이용에 관한 동의 후 3년간 개인정보를
                    보유하고 이후 해당 정보를 지체없이 파기합 니다 . 단, 법률에
                    의해 보존의무가 있는 경우에는 법령이 지정한 일정기간 동안
                    보존합니다 .
                  </pre>
                  <br />
                  <div className="checks">
                    <input type="checkbox" id="agree" />
                    <label htmlFor="agree">
                      위의 '개인정보 수집 및 이용'에 동의 합니다. (미동의시
                      상담이 제한될 수 있습니다.)
                    </label>
                  </div>
                  <button>확인</button>
                </form>
                <span className="big_span">
                  문의 남겨주시면 연락드리겠습니다
                </span>
              </div>
            </div>
          </section>
          <footer>
            <div className="footer_inner">
              <div className="footer_logo"></div>
              <div className="footer_text">
                서울 마포구 마포대로 122, 프론트원 13층
                <br />
                상호: ㈜통하는사람들 / 개인정보관리책임자: 홍영대
                <br />
                사업자등록번호: 783-88-02775
                <br/>
                Tel : 02-3272-2302 / Fax : 02-6455-9114 / E-mail : admin@hongtong.kr
              </div>
              <div className="footer_etc">
                <div>
                  {/* <div className="youtube"></div>
                  <div className="facebook"></div>
                  <div className="blog"></div> */}
                  <br />
                  copyright (c) 2023 all rights reserved.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}

export default LandingPage;
